import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Stack, Tooltip,
    Typography
} from "@mui/material";
import Popup from "../../components/Popup/Popup";
import {ProposalAssignment} from "../../components/Forms/FreelanceProfile/ProposalAssignment/ProposalAssignment";
import {FaBriefcase} from "react-icons/fa6";
import {fetchMission, fetchProposalMissions} from "../../services/missionService";
import {useSelector} from "react-redux";
import TagService from "../../services/tagService";

export const SelectedFreelanceFooter = ({selectedFreelances, onProposalAssignmentFinish, onCancelSelecting}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [anchorEl, setAnchorEl] = useState(null);
    const [proposalAssignmentDialog, setProposalAssignmentDialog] = useState(false);
    const defaultProposalAssignmentData = {
        type: 'proposal'
    };
    const [proposalAssignmentData, setProposalAssignmentData] = useState(defaultProposalAssignmentData);
    const [proposalAssignments, setProposalAssignments] = useState([]);

    const loadAssignments = useCallback((query = {}) => {
        try {
            fetchProposalMissions(query, token).then((fetchedData) => {
                if (!fetchedData?.error) {
                    setProposalAssignments(fetchedData.data);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    useEffect(() => {
        loadAssignments();
    }, []);

    const handleAssignmentClick = (assignment) => {
        handleMenuClose();
        fetchMission(assignment.id, token).then((fetchedMission) => {
            if (fetchedMission.MissionTags) {
                fetchedMission.MissionTags = fetchedMission.MissionTags.map(mt => mt.Tag);
                fetchedMission.MissionTags = TagService.groupTagsByType(fetchedMission.MissionTags);
            }
            setProposalAssignmentData(fetchedMission);
            setProposalAssignmentDialog(true);
        });
    };

    const handleCancelSelecting = () => {
        onCancelSelecting();
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openMenu = Boolean(anchorEl);
    const id = openMenu ? 'change-status-popover' : undefined;

    return (
        <>
            {selectedFreelances.length > 0 && (
                <Box id="selectedItemFooter">
                    <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
                        <Typography sx={{fontWeight: "bold", textTransform: "lowercase", whiteSpace: "nowrap"}}>
                            {selectedFreelances.length} {t("Freelancer(s)")}
                        </Typography>
                        <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                            <>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        setProposalAssignmentData(defaultProposalAssignmentData);
                                        setProposalAssignmentDialog(true);
                                    }}
                                    size="small"
                                >
                                    {t("Propose a new assignment")}
                                </Button>
                                <Popup
                                    open={proposalAssignmentDialog}
                                    onDialogClose={() => setProposalAssignmentDialog(false)}
                                >
                                    <Box sx={{width: {md: "40vw"}}}>
                                        <ProposalAssignment
                                            assignment={proposalAssignmentData}
                                            selectedFreelances={selectedFreelances}
                                            onSaveFinish={onProposalAssignmentFinish}
                                        />
                                    </Box>
                                </Popup>
                            </>
                            <>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleMenuClick}
                                    size="small"
                                >
                                    {t("Propose an existing assignment")}
                                </Button>
                                <Popover
                                    id={id}
                                    open={openMenu}
                                    anchorEl={anchorEl}
                                    onClose={handleMenuClose}
                                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                    transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                    sx={{mt: -1.5}}
                                >
                                    <Stack
                                        direction="column"
                                        spacing={1}
                                        sx={{p: 1.5, width: "300px", maxHeight: "300px", overflowY: "auto"}}
                                    >
                                        <List dense={false} disablePadding>
                                            {proposalAssignments.map((assignment) => {
                                                return (
                                                    <ListItem key={assignment.id} disablePadding>
                                                        <ListItemButton onClick={() => handleAssignmentClick(assignment)}>
                                                            <ListItemIcon>
                                                                <FaBriefcase size={15} className="Mui-icon"/>
                                                            </ListItemIcon>
                                                            <Tooltip
                                                                // classes={{popper: "MuiTooltip-light"}}
                                                                title={assignment.name}
                                                                placement="right"
                                                                disableInteractive
                                                            >
                                                                <ListItemText
                                                                    primary={assignment.name}
                                                                    primaryTypographyProps={{
                                                                        style: {
                                                                            whiteSpace: 'nowrap',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                        },
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Stack>
                                </Popover>
                            </>
                            <Button
                                variant="outlined"
                                color="light"
                                onClick={handleCancelSelecting}
                                size="small"
                            >
                                {t("Cancel")}
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            )}
        </>
    );
};