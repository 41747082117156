import React, {forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    DataGridPro,
    getGridStringOperators,
    getGridNumericOperators,
    getGridDateOperators, getGridSingleSelectOperators, GridColumnMenu
} from "@mui/x-data-grid-pro";
import {frFR, enUS} from '@mui/x-data-grid/locales';
import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    FormControl, Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Menu, MenuItem,
    OutlinedInput, Stack, TextField, Typography, useMediaQuery
} from "@mui/material";
import {MdClear, MdMoreHoriz, MdSearch} from "react-icons/md";

import './table.css';
import {FieldHolder} from "../Forms/FieldHolder";
import Utils from "../../utils/Utils";
import _, {debounce} from "lodash";
import dayjs from "dayjs";
import {fetchCitiesByCountry, fetchCity, fetchCountries} from "../../services/countryService";
import {useSelector} from "react-redux";
import {LicenseInfo} from '@mui/x-license';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const Table = forwardRef((
    {
        loading = false,
        fullHeight,
        displayConfig = {},
        advancedSearchColumns = [],
        data,
        filterConfigs,
        rowCount,
        checkboxSelection = false,
        isRowSelectable,
        rowSelectionModel,
        onRowSelectionModelChange,
        pageSize = 20,
        onChange,
        onCellClick,
        onCellDoubleClick,
        onDisplayConfigChange,
        children,
        ...props
    },
    ref
) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const {i18n} = useTranslation();
    const {language} = i18n;
    let localeTextTranslations = getLocaleTextTranslations(language);
    localeTextTranslations.noRowsLabel = '';
    localeTextTranslations.noResultsOverlayLabel = '';

    const hideFooter = rowCount <= 20;
    const pageSizeOptions = [20, 50, 100];
    let dataGridHeight;
    if (fullHeight) {
        let totalHeight = (data.length * 74) + 80; // Calcul automatique de la hauteur du tableau selon le nombre d'éléments à afficher
        if (!hideFooter) {
            totalHeight += 80; // Ajouter la hauteur du Footer au totalHeight
        }
        dataGridHeight = `${totalHeight}px`;
    }

    const _displayConfig = _.cloneDeep(displayConfig);

    const defaultPaginationModel = {page: 0, pageSize: pageSize};
    const defaultFilterModel = {items: []};
    const [rows, setRows] = React.useState([]);
    const [paginationModel, setPaginationModel] = useState(defaultPaginationModel);
    const [filterModel, setFilterModel] = useState(defaultFilterModel);
    const [sortModel, setSortModel] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [pinnedColumns, setPinnedColumns] = useState({});
    const [columnOrder, setColumnOrder] = useState([]);
    const [advancedSearchData, setAdvancedSearchData] = useState({});

    const hasMounted = useRef(false);

    const disableColumnSelector = props.disableColumnSelector ?? true;
    const disableColumnPinning = props.disableColumnPinning ?? true;
    const disableColumnReorder = props.disableColumnReorder ?? true;

    const columns = useMemo(
        () => {
            return props.columns.map((col) => {
                if (!['string', 'number', 'date', 'singleSelect'].includes(col.type)) {
                    col = {...col, sortable: false, filterable: false};
                }

                if ((typeof col.type === "undefined" && disableColumnSelector) || col.field === 'action') {
                    col = {...col, hideable: false, disableReorder: true, disableColumnMenu: true};
                }

                let operators;
                switch (col.type) {
                    case 'number':
                        operators = getGridNumericOperators().filter((op) => ['>=', '<='].includes(op.value));
                        break;
                    case 'date':
                        operators = getGridDateOperators().filter(
                            (op) => ['is', 'onOrAfter', 'onOrBefore'].includes(op.value)
                        );
                        break;
                    case 'singleSelect':
                        operators = getGridSingleSelectOperators().filter(
                            (op) => ['is'].includes(op.value)
                        );
                        break;
                    default:
                        operators = getGridStringOperators().filter((op) => ['contains'].includes(op.value));
                        break;
                }

                return {...col, filterOperators: operators};
            });
        }, [props.columns, disableColumnSelector]
    );
    const orderedColumns = _.orderBy(columns, col => columnOrder.indexOf(col.field));

    useEffect(() => {
        if (!loading) {
            setRows(data);
        }
    }, [data, loading]);

    useEffect(() => {
        if (!_displayConfig || hasMounted.current) return;

        const {columnVisibilityModel, pinnedColumns, columnOrder, advancedFilter} = _displayConfig;

        columnVisibilityModel && setColumnVisibilityModel(prevModel => {
            return !_.isEqual(prevModel, columnVisibilityModel) ? columnVisibilityModel : prevModel;
        });

        if (!isMobile && _displayConfig?.pinnedColumns) {
            setPinnedColumns((prevColumns) => {
                const checkboxColumn = "__check__";

                const updatedPinnedColumns = {
                    ...pinnedColumns,
                    left: pinnedColumns.left.includes(checkboxColumn)
                        ? pinnedColumns.left
                        : [checkboxColumn, ...pinnedColumns.left]
                };

                return !_.isEqual(prevColumns, updatedPinnedColumns) ? updatedPinnedColumns : prevColumns;
            });
        }

        columnOrder && setColumnOrder(prevOrder => {
            return !_.isEqual(prevOrder, columnOrder) ? columnOrder : prevOrder;
        });

        /*if (Object.keys(_displayConfig?.filterModel ?? {}).length) {
            handleFilterChange(_displayConfig.filterModel);
        }*/

        advancedFilter && setAdvancedSearchData(prevFilter => {
            return !_.isEqual(prevFilter, advancedFilter) ? advancedFilter : prevFilter;
        });

        hasMounted.current = true;
    }, [displayConfig, isMobile]);

    useEffect(() => {
        if (Object.keys(advancedSearchData).length) {
            let filters = {};

            let advancedSearchKeys = [...advancedSearchColumns.map(column => column.field)];
            advancedSearchKeys.push("search");

            for (const searchKey of advancedSearchKeys) {
                const value = Utils.getNestedValue(advancedSearchData, searchKey);
                if (Object.keys(value).length) {
                    if (searchKey === "search") {
                        if (value.value.toString().trim()) {
                            for (const column of columns) {
                                if (
                                    ['string', 'number'].includes(column.type) &&
                                    !column?.noSearchFilter && !column?.hideable
                                ) {
                                    filters[column.field] = {operator: 'contains', value: value.value};
                                }
                            }
                        }
                    } else {
                        if (
                            typeof value.min !== "undefined" && value.min &&
                            typeof value.max !== "undefined" && value.max
                        ) {
                            filters[searchKey] = {
                                cond: 'and',
                                operator: 'between',
                                value: [value.min, value.max]
                            };
                        } else if (typeof value.min !== "undefined" && value.min) {
                            filters[searchKey] = {
                                cond: 'and',
                                operator: '>=',
                                value: value.min
                            };
                        } else if (typeof value.max !== "undefined" && value.max) {
                            filters[searchKey] = {
                                cond: 'and',
                                operator: '<=',
                                value: value.max
                            };
                        } else if (typeof value.value !== "undefined" && value.value) {
                            filters[searchKey] = {
                                cond: 'and',
                                operator: 'is',
                                value: value.value
                            };
                        } else if (typeof value.values !== "undefined" && value.values.length) {
                            filters[searchKey] = {
                                cond: 'and',
                                operator: 'in',
                                value: value.values.map((v) => v.id)
                            };
                        } else if (typeof value.country !== "undefined" && value.country) {
                            filters[`${searchKey}.CountryId`] = {
                                cond: 'and',
                                operator: 'is',
                                value: value.country
                            };
                            if (typeof value.city !== "undefined" && value.city) {
                                filters[`${searchKey}.id`] = {
                                    cond: 'and',
                                    operator: 'is',
                                    value: value.city
                                };
                            }
                        }
                    }
                }
            }

            handleDataGridChange(filters);
            handleDisplayConfigChange('advancedFilter', advancedSearchData);
        }
    }, [advancedSearchData]);

    const handleDisplayConfigChange = (displayKey, displayValue) => {
        if (!_.isEqual(displayConfig?.[displayKey], displayValue)) {
            onDisplayConfigChange?.(displayKey, displayValue);
        }
    };

    const handleDataGridChange = (filters) => {
        // const parsedFilter = filterConfigs?.filter ? JSON.parse(filterConfigs.filter) : {};
        // if (!_.isEqual(parsedFilter, filters)) {
        const filterLength = Object.keys(filters).length;
        onChange?.({
            ...filterConfigs,
            activatedFilter: !!filterLength,
            filter: filterLength ? JSON.stringify(filters) : ''
        });
        // }
    };

    const handlePaginationChange = useCallback((params) => {
        setPaginationModel(params);

        const start = (params.page * params.pageSize);
        const end = start + (params.pageSize - 1);

        onChange && onChange({
            ...filterConfigs,
            range: JSON.stringify([start, end])
        });
        handleDisplayConfigChange('pageSize', params.pageSize);
    }, [filterConfigs]);

    const handleFilterChange = useCallback(debounce((model) => {
        setFilterModel(model);
        setAdvancedSearchData({});

        let filters = {};
        for (const filter of model.items) {
            const value = filter?.value ?? "";
            if (value !== "") {
                filters[filter.field] = {operator: filter.operator, value};
            }
        }

        handleDataGridChange(filters);
        handleDisplayConfigChange('filterModel', model);
    }, 300), []);

    const handleSortChange = useCallback((params) => {
        setSortModel(params);
        onChange && onChange({
            ...filterConfigs,
            sort: params.length ? JSON.stringify([params[0].field, params[0].sort]) : ''
        });
    }, [filterConfigs]);

    const handleColumnVisibilityModelChange = useCallback((newModel) => {
        setColumnVisibilityModel(newModel);
        handleDisplayConfigChange('columnVisibilityModel', newModel);
    }, []);

    const handlePinnedColumnsChange = useCallback((updatedPinnedColumns) => {
        setPinnedColumns(updatedPinnedColumns);
        handleDisplayConfigChange('pinnedColumns', updatedPinnedColumns);
    }, []);

    const handleColumnOrderChange = useCallback((params) => {
        const {targetIndex, oldIndex} = params;

        const {left = [], right = []} = pinnedColumns || {};

        const nonPinnedColumns = orderedColumns.filter(item => !right.includes(item.field));
        const nonPinnedOrder = nonPinnedColumns.map(item => item.field);

        // Déplacer la colonne dans le nouvel ordre des colonnes non-fixées
        const [movedColumn] = nonPinnedOrder.splice(oldIndex, 1);
        nonPinnedOrder.splice(targetIndex, 0, movedColumn);

        // Combiner les colonnes non-pinned réorganisées, tout en assurant que les "left" restent au début
        const updatedOrder = [
            ...left.map(item => item.field),
            ...nonPinnedOrder.filter(field => !left.includes(field)),
            ...right.map(item => item.field)
        ];

        setColumnOrder(updatedOrder);
        handleDisplayConfigChange('columnOrder', updatedOrder);
    }, [orderedColumns, pinnedColumns]);

    const handleCellClick = (params, event) => {
        if (params.field === '__check__') {
            event.stopPropagation();
            return;
        }
        onCellClick && onCellClick(params.row);
    };

    const handleCellDoubleClick = (params) => {
        if (!params.isEditable) {
            onCellDoubleClick && onCellDoubleClick(params.row);
        }
    };

    const handleProcessRowUpdate = (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleInitTable = ({initFilter = true}) => {
        if (initFilter) {
            setAdvancedSearchData({});
        }
        setPaginationModel(defaultPaginationModel);
        setFilterModel(defaultFilterModel);
        setSortModel([]);
    };

    if (ref) {
        ref.current = {
            initTable: handleInitTable
        };
    }

    return (
        <Box className="table-container">
            {!props.hideFilter && (
                <TableFilter
                    advancedSearchColumns={advancedSearchColumns}
                    advancedSearchData={advancedSearchData}
                    setAdvancedSearchData={setAdvancedSearchData}
                >{children}</TableFilter>
            )}
            <Box className="table-content" sx={{height: dataGridHeight}}>
                <DataGridPro
                    pagination
                    // loading={loading}
                    className={props.className}
                    disableVirtualization
                    disableColumnResize
                    disableRowSelectionOnClick
                    disableMultipleColumnsSorting
                    disableMultipleColumnsFiltering
                    keepNonExistentRowsSelected
                    disableColumnSelector={disableColumnSelector}
                    disableColumnPinning={disableColumnPinning}
                    disableColumnReorder={disableColumnReorder}
                    checkboxSelection={checkboxSelection}
                    isRowSelectable={isRowSelectable}
                    columns={orderedColumns}
                    rows={rows}
                    rowCount={rowCount}
                    localeText={localeTextTranslations}
                    slots={{columnMenu: CustomColumnMenu}}
                    slotProps={{
                        columnsManagement: {disableShowHideToggle: true, disableResetButton: true},
                    }}
                    columnMenuIcon={<div style={{marginLeft: 'auto'}}>&#8942;</div>}
                    pageSizeOptions={pageSizeOptions}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationChange}
                    filterMode="server"
                    filterModel={filterModel}
                    onFilterModelChange={handleFilterChange}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortChange}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                    pinnedColumns={pinnedColumns}
                    onPinnedColumnsChange={handlePinnedColumnsChange}
                    onColumnOrderChange={handleColumnOrderChange}
                    onCellClick={handleCellClick}
                    onCellDoubleClick={handleCellDoubleClick}
                    processRowUpdate={handleProcessRowUpdate}
                    hideFooter={hideFooter}
                    rowHeight={61}
                />
            </Box>
        </Box>
    );
});

const TableFilter = memo((
    {
        advancedSearchColumns,
        advancedSearchData,
        setAdvancedSearchData,
        children
    }
) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [advancedSearch, toggleAdvancedSearch] = useState(false);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    const fetchCitiesRef = useRef();
    const searchInputRef = useRef();
    const hasMounted = useRef(false);
    const filterConfigs = {
        filter: '',
        range: JSON.stringify([0, 199]),
    };

    useEffect(() => {
        if (!Object.keys(advancedSearchData).length) return;

        if (advancedSearchData?.Location?.city) {
            fetchCity(advancedSearchData?.Location?.city, token).then((fetchedData) => {
                if (!fetchedData?.error) {
                    console.log(fetchedData);
                    setCities([fetchedData]);
                }
            });
        }

        if (hasMounted.current) return;

        if (advancedSearchData?.search?.value) {
            searchInputRef.current.value = advancedSearchData.search.value;
        }

        hasMounted.current = true;
    }, [advancedSearchData]);

    useEffect(() => {
        const advancedSearchTypes = advancedSearchColumns.map(column => column.type);
        if (advancedSearchTypes.includes('location')) {
            (async () => {
                const fetchedCountries = await fetchCountries(token);
                setCountries(fetchedCountries.data);
            })();
        }
    }, []);

    const handleChange = debounce((e) => {
        let {name, value} = e.target;
        value = value === null ? '' : value.toString();
        setAdvancedSearchData(prevData => Utils.updateNestedField(prevData, name, value));
    }, 300);

    const handleClear = () => {
        setAdvancedSearchData(prevData => Utils.updateNestedField(prevData, "search.value", ""));
        searchInputRef.current.value = "";
        searchInputRef.current.focus();
    };

    const handleCountryChange = (name, value) => {
        if (value) {
            searchCities(value);
        } else {
            setCities([]);
        }

        let newFormData = Utils.updateNestedField(advancedSearchData, name, value);
        newFormData = Utils.updateNestedField(newFormData, "Location.city", "");
        setAdvancedSearchData(newFormData);
    };

    const handleInputCityChange = useCallback(
        debounce((inputValue) => {
            if (advancedSearchData?.Location?.country) {
                searchCities(advancedSearchData?.Location?.country, inputValue);
            }
        }, 300), // 300ms debounce time
        []
    );

    const handleCancelFilter = () => {
        const newFormData = Utils.updateNestedField({}, "search.value", "");
        setAdvancedSearchData(newFormData);
    };

    const searchCities = (countryId, inputValue = "") => {
        let filters = {};
        if (inputValue) {
            filters = {
                name: {
                    operator: 'contains',
                    value: inputValue
                }
            };
        }

        fetchCitiesRef.current(countryId, {
            ...filterConfigs,
            filter: Object.keys(filters).length ? JSON.stringify(filters) : ''
        });
    };

    fetchCitiesRef.current = useCallback((countryId, query = {}) => {
        try {
            fetchCitiesByCountry(countryId, query, token).then((fetchedCities) => {
                setCities(fetchedCities.data);
            });
        } catch (error) {

        }
    }, []);

    const advancedSearchContent = useMemo(() => {
        let _advancedSearch = false;
        const _advancedSearchContent = advancedSearchColumns.map((column) => {
            let res;
            column.headerName = column.shortName ?? column.headerName;
            switch (column.type) {
                case 'number':
                    const minValue = Utils.getNestedValue(advancedSearchData, `${column.field}.min`, "");
                    const maxValue = Utils.getNestedValue(advancedSearchData, `${column.field}.max`, "");
                    if (minValue || maxValue) {
                        _advancedSearch = true;
                    }

                    res = <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label={`${column.headerName} Min`}
                                type="number"
                                name={`${column.field}.min`}
                                id={`${column.field}.min`}
                                placeholder="Min"
                                defaultValue={minValue}
                                onChange={handleChange}
                                autoComplete="off"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={`${column.headerName} Max`}
                                type="number"
                                name={`${column.field}.max`}
                                id={`${column.field}.max`}
                                placeholder="Max"
                                defaultValue={maxValue}
                                onChange={handleChange}
                                autoComplete="off"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    break;
                case 'dateRange':
                    const startDate = Utils.getNestedValue(advancedSearchData, `${column.field}.min`, null);
                    const endDate = Utils.getNestedValue(advancedSearchData, `${column.field}.max`, null);
                    if (startDate || endDate) {
                        _advancedSearch = true;
                    }

                    res = <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={6}>
                            <FieldHolder
                                fielddesc={`${column.headerName} entre`}
                                type="date"
                                name={`${column.field}.min`}
                                id={`${column.field}.min`}
                                placeholder={`${column.headerName} entre`}
                                value={startDate}
                                onChange={handleChange}
                                canModify={true}
                                minDate={null}
                                maxDate={endDate ? dayjs(endDate) : null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FieldHolder
                                fielddesc={`Et`}
                                type="date"
                                name={`${column.field}.max`}
                                id={`${column.field}.max`}
                                placeholder="Et"
                                value={endDate}
                                onChange={handleChange}
                                canModify={true}
                                minDate={startDate ? dayjs(startDate) : null}
                                maxDate={null}
                            />
                        </Grid>
                    </Grid>
                    break;
                case 'singleSelect':
                    const value = Utils.getNestedValue(advancedSearchData, `${column.field}.value`, "");
                    if (value) {
                        _advancedSearch = true;
                    }

                    res = <FieldHolder
                        fielddesc={column.headerName}
                        type="dropdown"
                        name={`${column.field}.value`}
                        id={`${column.field}.value`}
                        value={value}
                        datas={column.data}
                        formData={advancedSearchData}
                        setFormData={setAdvancedSearchData}
                        disableClearable={false}
                        canModify={true}
                    />
                    break;
                case 'multipleSelect':
                    const values = Utils.getNestedValue(advancedSearchData, `${column.field}.values`, []);
                    if (values.length) {
                        _advancedSearch = true;
                    }

                    res = <FieldHolder
                        fielddesc={column.headerName}
                        type="dropdown"
                        name={`${column.field}.values`}
                        id={`${column.field}.values`}
                        value={values}
                        datas={column.data}
                        formData={advancedSearchData}
                        setFormData={setAdvancedSearchData}
                        canModify={true}
                        ismulti={true}
                        allowGroup={true}
                    />
                    break;
                case 'location':
                    const countryValue = Utils.getNestedValue(advancedSearchData, `${column.field}.country`, "");
                    const cityValue = Utils.getNestedValue(advancedSearchData, `${column.field}.city`, "");
                    if (countryValue || cityValue) {
                        _advancedSearch = true;
                    }

                    res = <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={6}>
                            <FieldHolder
                                fielddesc={t("Country")}
                                type="dropdown"
                                name={`${column.field}.country`}
                                id={`${column.field}.country`}
                                value={countryValue}
                                onDropdownChange={handleCountryChange}
                                datas={countries}
                                formData={advancedSearchData}
                                setFormData={setAdvancedSearchData}
                                disableClearable={false}
                                canModify={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FieldHolder
                                fielddesc={t("Localization")}
                                type="dropdown"
                                name={`${column.field}.city`}
                                id={`${column.field}.city`}
                                value={cityValue}
                                datas={cities}
                                formData={advancedSearchData}
                                setFormData={setAdvancedSearchData}
                                onInputChange={handleInputCityChange}
                                disableClearable={false}
                                canModify={true}
                            />
                        </Grid>
                    </Grid>
                    break;
                default:
                    break;
            }
            return <Grid item key={column.headerName} xs={12} md={4}>{res}</Grid>;
        });

        if (_advancedSearch) {
            toggleAdvancedSearch(true);
            _advancedSearchContent.push(
                <Grid item key="filter.cancel" xs={12} md={4}>
                    <Stack alignItems="start">
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={handleCancelFilter}
                        >
                            {t("Cancel filters")}
                        </Button>
                    </Stack>
                </Grid>
            );
        }

        return _advancedSearchContent;
    }, [t, advancedSearchData, countries, cities]);

    const searchInput = useMemo(() => (
        <FormControl id="searchContainer" variant="outlined">
            <InputLabel htmlFor="search">{t("Search")}</InputLabel>
            <OutlinedInput
                inputRef={searchInputRef}
                type="text"
                name="search.value"
                id="search.value"
                defaultValue=""
                onChange={handleChange}
                endAdornment={
                    <InputAdornment position="end">
                        {searchInputRef?.current?.value && (
                            <Box mr={1}>
                                <IconButton onClick={handleClear} edge="end">
                                    <MdClear />
                                </IconButton>
                            </Box>
                        )}
                        <IconButton edge="end">
                            <MdSearch />
                        </IconButton>
                    </InputAdornment>
                }
                label={t("Search")}
            />
        </FormControl>
    ), [t, searchInputRef?.current?.value]);

    return (
        <Stack direction="column" alignItems="flex-start" spacing={0.5} sx={{width: "100%", marginBottom: "15px"}}>
            <Stack direction={{xs: "column", md: "row"}} alignItems="center" spacing={2} sx={{width: "100%"}}>
                {searchInput}
                <Stack direction="row" spacing={2}>
                    {advancedSearchContent.length > 0 && (
                        <Button
                            variant={!advancedSearch ? "contained" : "outlined"}
                            color="info"
                            size="small"
                            onClick={() => toggleAdvancedSearch((v) => !v)}
                        >
                            {t("Advanced search")}
                        </Button>
                    )}
                    {children}
                </Stack>
            </Stack>
            {
                advancedSearchContent.length > 0 &&
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    gap: "15px",
                    width: "100%",
                    marginTop: "15px"
                }}>
                    <Grid container alignItems="center" spacing={2}>
                        {advancedSearch && <>{advancedSearchContent}</>}
                    </Grid>
                </div>
            }
        </Stack>
    );
}, (prevProps, nextProps) => {
    return false;
});

const getLocaleTextTranslations = (language) => {
    switch (language) {
        case 'en':
            return enUS.components.MuiDataGrid.defaultProps.localeText;
        default:
            return frFR.components.MuiDataGrid.defaultProps.localeText;
    }
};

const CustomColumnMenu = (props) => {
    return (
        <GridColumnMenu
            {...props}
        />
    )
};

const TableActionMenu = ({row, onEdit, onDelete}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleActionClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = (e = null) => {
        e?.preventDefault();
        e?.stopPropagation();

        setAnchorEl(null);
    };

    const handleEdit = (e, id) => {
        e.preventDefault();
        e.stopPropagation();

        handleMenuClose();
        onEdit && onEdit(id);
    };

    const handleDelete = (e, id) => {
        e.preventDefault();
        e.stopPropagation();

        handleMenuClose();
        onDelete && onDelete(id);
    };

    return <>
        <IconButton
            aria-controls="item-actions-menu"
            aria-haspopup="true"
            onClick={handleActionClick}
            size="small"
        >
            <MdMoreHoriz size={23}/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={(e) => handleMenuClose(e)}
        >
            {onEdit && (
                <MenuItem onClick={(e) => handleEdit(e, row.id)}>{t("Edit")}</MenuItem>
            )}
            {onDelete && (
                <MenuItem onClick={(e) => handleDelete(e, row.id)}>
                    <Typography color="secondary">{t("Delete")}</Typography>
                </MenuItem>
            )}
        </Menu>
    </>
};

export {
    Table,
    TableFilter,
    CustomColumnMenu,
    TableActionMenu
}